import React, { useEffect, useContext } from "react";
import Login from "./scenes/login";
import { Routes, Route, useNavigate } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Controller from "./scenes/controller";
import DeleteCompany from "./scenes/DeleteCompany";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import Protected from "./ProtectedRoute";
// import { AuthContext } from "./hooks/context";
import { AuthContext } from "./Auth";

import { NotificationContainer } from "react-notifications";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = React.useState(true);
  // const [LoggedIn, setLoggedIn] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn || !localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  // console.log("tohar login is ", isLoggedIn);

  const logout = () => {
    alert("clicked on logout");

    localStorage.removeItem("token");

    // setIsLoggedIn(false);
    navigate("/login");
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />

          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} logout={logout} />

            <NotificationContainer />

            <Routes>
              {/* <Route path="/" element={<Login />} /> */}
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={<Protected isLoggedIn={isLoggedIn}></Protected>}
              ></Route>
              <Route path="/controller" element={<Controller />} />
              <Route path="/deleteCompany" element={<DeleteCompany />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
