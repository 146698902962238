import React, {useEffect, useState} from "react";
import { 
Box, Grid, Button, TextField, Typography, FormControlLabel, Checkbox, Link, Container } from "@mui/material";

import baseUrl from "../../api";
import { AuthContext } from '../../Auth';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

const Login = () => {
    const [formValue, setFormValue] = useState({
        userId:"",
        userPassword:""
    });
    const [loading, setLoading] = useState(false);
    // const auth = AuthContext();
    const auth = React.useContext(AuthContext);
    const navigate = useNavigate();

    const onLogin = (e) => {
        e.preventDefault()
       
        if (formValue.userId.length == 0) {
            NotificationManager.error('User Id required !');
            return;
        }
        if (formValue.userPassword.length == 0) {
            NotificationManager.error('userPassword required !');
            return;
        }
        auth.onLogin(formValue);
    };

      
      const onChangeItemDetails = (key, value) => {
      var arr = { ...formValue };
      arr[key] = value;
      setFormValue((prevState) => arr);
      };
    
      const handleOnAddItem = (e)=>{
      e.preventDefault();
     
      
    };

  return (
    <Container component="main" maxWidth="xs">
        <Box
            sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "#0a0f1c",
            }}
        >
            <Typography component="h1" variant="h5" color="secondary">
                Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField fullWidth
                    label="Enter User Name"
                    value={formValue.userId}
                    onChange={(e) =>
                        onChangeItemDetails("userId", e.target.value)
                    }
                    size="small" color="secondary"
                    margin="normal"
                />
                <TextField fullWidth 
                    label="Enter userPassword"
                    type="userPassword"
                    value={formValue.userPassword}
                    onChange={(e) =>
                        onChangeItemDetails("userPassword", e.target.value)
                    }
                    size="small" color="secondary"
                    margin="normal"
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="secondary" />}
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    onClick={(e) => onLogin(e)}
                    variant="contained" color="secondary"
                    sx={{ py: 1.5, mt: 3, mb: 2 }}
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item xs>
                    <Link href="#" variant="body2" color="secondary">
                        Forgot userPassword?
                    </Link>
                    </Grid>
                    <Grid item>
                    <Link href="#" variant="body2" color="secondary">
                        {"Don't have an account? Sign Up"}
                    </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Container>
  );
};

export default Login;