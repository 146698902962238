import React, { useState } from "react";
import { 
Box, Button, TextField, useTheme, useMediaQuery
} from "@mui/material";

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import axiosInstance from "../../api/axiosInstance";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { mockDataQuickResult } from "../../data/mockData";

//DataGridTable
import { DataGrid } from '@mui/x-data-grid';

const Controller = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  //colors
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: 'id', headerName: 'ID', cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",},
    {
      field: 'testName',
      headerName: 'Test Name',
      width: 110,
      editable: true,
      cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
    },
    {
      field: 'subTest',
      headerName: 'Sub Test',
      width: 110,
      editable: true,
      cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
    },
    {
      field: 'result',
      headerName: 'Result',
      width: 110,
      editable: true,
      cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 110,
      editable: true,
      cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
    },
    {
      field: 'normalValue',
      headerName: 'Normal Value',
      width: 110,
      editable: true,
      cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 110,
      editable: true,
      cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
    },
    {
      field: 'rp',
      headerName: 'R.P.',
      width: 110,
      editable: true,
      cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
    },
    {
      field: 'printNo',
      headerName: 'Print No',
      width: 110,
      editable: true,
      cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
    },
  ];

  //date
  const [createdAt, setCreatedAt] = React.useState(null);
  const [updatedAt, setUpdatedAt] = React.useState(null);
  
  const [itemDetails, setItemDetails] = useState({
    loginId:"",
    password:"",
    status:"",
    companyEmail: "",
    validUpto: "",
    createdBy: "",
    updatedBy: "",
    permissionGroupId: "",
    companyName: "",
  });
  
  const onChangeItemDetails = (key, value) => {
  var arr = { ...itemDetails };
  arr[key] = value;
  setItemDetails((prevState) => arr);
  };

  const handleOnAddItem = (e)=>{
  e.preventDefault();
  // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));
  
  console.log("your form value is ", itemDetails)
  // console.log(`${baseUrl}/api/master/addConsultantDoctorMaster`)
  // axios
  // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
  // )
  // .then((response) => {
  //     // successfully uploaded response
  // })
  // .catch((error) => {
  //     // error response
  // });
  // alert(`${baseUrl}/api/booking/quickResult`);
  };
  const handleSave = (e)=>{
    e.preventDefault();
    alert("Account has been created successfully")
    
    console.log("your form value is ", itemDetails)

    axiosInstance
    .post(`/createCompany`,itemDetails)
    .then((response) => {
      alert("Company Created")
      // console.log("Created")
        // successfully uploaded response
    })
    .catch((error) => {
        // error response
    });
    };
  
  return (
    <Box m="20px">
      <Header title="Company Controller" subtitle="Manage a Company License" />

      <Box
        display="grid"
        gap="10px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
          mb: 1,
        }}
      >
        <TextField fullWidth 
          type="text"
          label="Login Id"
          value={itemDetails.loginId}
          onChange={(e) =>
            onChangeItemDetails("loginId", e.target.value)
          }
          size="small" color="secondary"
        />
        <TextField fullWidth 
          type="password"
          label="Password"
          value={itemDetails.password}
          onChange={(e) =>
            onChangeItemDetails("password", e.target.value)
          }
          size="small" color="secondary"
        />
        <TextField fullWidth 
          type="text"
          label="Status"
          value={itemDetails.status}
          onChange={(e) =>
            onChangeItemDetails("status", e.target.value)
          }
          size="small" color="secondary"
        />
        <TextField fullWidth 
          type="text"
          label="companyEmail"
          value={itemDetails.companyEmail}
          onChange={(e) =>
            onChangeItemDetails("companyEmail", e.target.value)
          }
          size="small" color="secondary"
        />
        <TextField fullWidth 
          type="text"
          label="Valid Upto"
          value={itemDetails.validUpto}
          onChange={(e) =>
            onChangeItemDetails("validUpto", e.target.value)
          }
          size="small" color="secondary"
        />
        <TextField fullWidth 
          type="text"
          label="Created By"
          value={itemDetails.createdBy}
          onChange={(e) =>
            onChangeItemDetails("createdBy", e.target.value)
          }
          size="small" color="secondary"
        />
        <TextField fullWidth 
          type="text"
          label="Updated By"
          value={itemDetails.updatedBy}
          onChange={(e) =>
            onChangeItemDetails("updatedBy", e.target.value)
          }
          size="small" color="secondary"
        />
        <TextField fullWidth 
          type="text"
          label="Permission Group Id"
          value={itemDetails.permissionGroupId}
          onChange={(e) =>
            onChangeItemDetails("permissionGroupId", e.target.value)
          }
          size="small" color="secondary"
        />
        <TextField fullWidth 
          type="text"
          label="Company Name"
          value={itemDetails.companyName}
          onChange={(e) =>
            onChangeItemDetails("companyName", e.target.value)
          }
          size="small" color="secondary"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker sx={{ minWidth: "100%" }}
            label="Created At"
            format="DD/MM/YYYY"
            value={createdAt}
            onChange={(newCreatedAt) => { setCreatedAt(newCreatedAt); }}
            slotProps={{ textField: { size: 'small', color:'secondary' } }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker sx={{ minWidth: "100%" }}
            label="Updated At"
            format="DD/MM/YYYY"
            value={updatedAt}
            onChange={(newUpdatedAt) => { setUpdatedAt(newUpdatedAt); }}
            slotProps={{ textField: { size: 'small', color:'secondary' } }}
          />
        </LocalizationProvider>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        gap="10px"
        my="15px"
      >
        <Button type="submit" color="secondary" variant="contained" onClick={handleOnAddItem}>Update</Button>
        <Button type="submit" color="secondary" variant="contained" onClick={handleSave}>Save</Button>
        <Button type="submit" color="secondary" variant="contained" onClick={handleOnAddItem}>Cancel</Button>
      </Box>
      <Box m="40px 0 0 0"
        height="50vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-row, .MuiDataGrid-cellCheckbox, MuiDataGrid-cell": {
            minHeight: "34px !important",
            maxHeight: "34px !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}>
        <DataGrid
          rows={mockDataQuickResult}
          columns={columns}
          checkboxSelection
          pageSize={100}
        />
      </Box>
    </Box>
  );
};

export default Controller;