import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import axiosInstance from "./api/axiosInstance";
import { NotificationManager } from "react-notifications";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (formValue) => {
    alert("submitting");

    await axiosInstance
      .post("/loginSuper", formValue)
      .then((response) => {
        // console.log("response", response);
        if (response.data.status == "success") {
          localStorage.setItem("token", response.data.token);
          let expiresAt = JSON.stringify(1000 * 1000 + new Date().getTime());
          setIsLoggedIn(true);
          navigate("/controller");
          NotificationManager.success("User Login Successfully!");
        } else {
          NotificationManager.success("Wrong Credentials");
        }
        // let expiresAt = JSON.stringify(response.data.expiration * 1000 + new Date().getTime());
      })
      .catch((error) => {
        // NotificationManager.error(JSON.stringify(error.response.data));
      });
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user_id");
    localStorage.removeItem("tenantID");
    navigate("/login");
  };

  const value = {
    isLoggedIn,
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  // return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export { AuthContext, AuthProvider };
// export const useAuth = () => {
//   return React.useContext(AuthContext);
// };
