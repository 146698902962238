import React, { useState } from "react";
import { 
Box, Button, TextField, useTheme, useMediaQuery
} from "@mui/material";

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import axiosInstance from "../../api/axiosInstance";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { mockDataQuickResult } from "../../data/mockData";

//DataGridTable
// import { DataGrid } from '@mui/x-data-grid';

const DeleteCompany = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  //colors
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const columns = [
  //   { field: 'id', headerName: 'ID', cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",},
  //   {
  //     field: 'testName',
  //     headerName: 'Test Name',
  //     width: 110,
  //     editable: true,
  //     cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
  //   },
  //   {
  //     field: 'subTest',
  //     headerName: 'Sub Test',
  //     width: 110,
  //     editable: true,
  //     cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
  //   },
  //   {
  //     field: 'result',
  //     headerName: 'Result',
  //     width: 110,
  //     editable: true,
  //     cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
  //   },
  //   {
  //     field: 'unit',
  //     headerName: 'Unit',
  //     width: 110,
  //     editable: true,
  //     cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
  //   },
  //   {
  //     field: 'normalValue',
  //     headerName: 'Normal Value',
  //     width: 110,
  //     editable: true,
  //     cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
  //   },
  //   {
  //     field: 'comment',
  //     headerName: 'Comment',
  //     width: 110,
  //     editable: true,
  //     cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
  //   },
  //   {
  //     field: 'rp',
  //     headerName: 'R.P.',
  //     width: 110,
  //     editable: true,
  //     cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
  //   },
  //   {
  //     field: 'printNo',
  //     headerName: 'Print No',
  //     width: 110,
  //     editable: true,
  //     cellClassName: "MuiDataGrid-row MuiDataGrid-cellCheckbox MuiDataGrid-cell",
  //   },
  // ];

  //date
 
  
  const [itemDetails, setItemDetails] = useState({
    companyEmail:"",
    
  });
  
  const onChangeItemDetails = (key, value) => {
  var arr = { ...itemDetails };
  arr[key] = value;
  setItemDetails((prevState) => arr);
  };


  const handleSave = (e)=>{
    e.preventDefault();
    console.log("item details ", itemDetails)
    // alert("Account has been Deleted successfully")
    
    // console.log("your form value is ", itemDetails)

    axiosInstance
    .delete(`/deleteCompany/${itemDetails.companyEmail}`,)
    .then((response) => {
      // alert("Company Deleted")
      // console.log("Created")
        // successfully uploaded response
    })
    .catch((error) => {
        // error response
    });
    };
  
  return (
    <Box m="20px">
      <Header title="DeleteCompany" subtitle="Manage a Company License" />

      <Box
        display="grid"
        gap="10px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
          mb: 1,
        }}
      >
        <TextField fullWidth 
          type="text"
          label="Company Email"
          value={itemDetails.companyEmail}
          onChange={(e) =>
            onChangeItemDetails("companyEmail", e.target.value)
          }
          size="small" color="secondary"
        />
        
       
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        gap="10px"
        my="15px"
      >
        <Button type="submit" color="error" variant="contained" onClick={handleSave}>Delete Company</Button>
      </Box>
      {/* <Box m="40px 0 0 0"
        height="50vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-row, .MuiDataGrid-cellCheckbox, MuiDataGrid-cell": {
            minHeight: "34px !important",
            maxHeight: "34px !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}>
        <DataGrid
          rows={mockDataQuickResult}
          columns={columns}
          checkboxSelection
          pageSize={100}
        />
      </Box> */}
    </Box>
  );
};

export default DeleteCompany;